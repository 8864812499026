<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Employee</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'employees'}">
                        <span>Employees</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create</li>
            </ol>
        </nav>
        <b-container fluid v-model="page_loader">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div >
                <h4>Employee Details</h4>
                <hr>
                <form>
                    <div class="col-md-8">
                        <div class="form-group row">
                            <label class="col-md-3">
                            Emp ID
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-input-group prepend="IES - ">
                                    <b-form-input aria-label="id" v-model="suggested_id"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Email
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <ValidationProvider
                                    name="email"
                                    rules="required|email|max:198"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        aria-describedby="emailHelpInline"
                                        v-model="employee.email"
                                        autocomplete="off"
                                    />
                                    <small v-if="errors.length != 0" id="emailHelpInline" class="text-danger field">
                                        {{
                                        errors[0]
                                        }}
                                    </small>
                                </ValidationProvider>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <label class="col-md-3">
                                Password
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-6">
                                <ValidationProvider
                                    name="password"
                                    rules="required|min:8|max:20"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="password"
                                        aria-describedby="passwordHelpInline"
                                        v-model="employee.password"
                                        autocomplete="off"
                                    />
                                    <small
                                        v-if="errors.length == 0"
                                        id="passwordHelpInline"
                                        class="text-muted field"
                                    >Atleast 8 alphanumeric characters</small>
                                    <small
                                        v-else
                                        id="passwordHelpInline"
                                        class="text-danger field"
                                    >{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-1">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="PasswordCharGenerate"
                                ><b-icon icon="key" aria-hidden="true"></b-icon></button>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Roles
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-group>
                                    <ValidationProvider
                                        name="roles"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <b-form-checkbox-group
                                            id="checkbox-group-1"
                                            v-model="employee.role"
                                            :options="roles"
                                            name="flavour-1"
                                            stacked
                                            required
                                        ></b-form-checkbox-group>
                                        <small
                                            v-if="errors.length == 0"
                                            id="rolesHelpInline"
                                            class="text-muted field"
                                        >Select one or more Roles</small>
                                        <small v-else id="rolesHelpInline" class="text-danger field">
                                            {{
                                            errors[0]
                                            }}
                                        </small>
                                    </ValidationProvider>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3">
                                Full Name
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                 <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="fullnameHelpInline"
                                    @change="textTitleCase($event, 'full_name')"
                                    v-model="employee.full_name"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Date of Joining
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-input-group size="md" class="mb-1em">
                                    <date-pick
                                        v-model="employee.doj"
                                        :selectableYearRange ="doj_selectable_years"
                                        :displayFormat="'DD-MM-YYYY'"
                                    ></date-pick>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Designation
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="DesignationHelpInline"
                                    v-model="employee.designation"
                                    autocomplete="off"
                                    v-on:blur="textTitleCase($event, 'designation')"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Employee Group
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <b-form-select
                                    value-field="item"
                                    text-field="name"
                                    v-model="employee.employee_group_id"
                                    :options="employee_groups"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Holiday Group
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <b-form-select
                                    value-field="item"
                                    text-field="name"
                                    v-model="employee.holiday_group_id"
                                    :options="holiday_groups"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Leave Group
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <b-form-select
                                    value-field="item"
                                    text-field="name"
                                    v-model="employee.leave_group_id"
                                    :options="leave_groups"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Week offs
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <multiselect
                                    label="name"
                                    track-by="value"
                                    placeholder="Select Week off"
                                    v-model="week_off"
                                    :options="week_offs"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :taggable="true"
                                ></multiselect>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Reporting To
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <EmployeeList @emp_id="getEmpId"></EmployeeList>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                               Initial Leave Credits
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="number"
                                    class="form-control"
                                    aria-describedby="Leaves Initial Credit HelpInline"
                                    v-model="employee.leaves_initial_credit"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                UAN Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="UanHelpInline"
                                    v-model="employee.uan"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                ESIC Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="EsicHelpInline"
                                    v-model="employee.esic"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                    </div>

                    <h4 class="mb-3 mt-3">Salary Details</h4>
                    <hr>
                    <div class="col-md-8">
                        <div class="form-group row">
                            <label class="col-md-3">
                                Offered Gross per Year
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    v-model="employee.offered_gross_per_year"
                                    type="text"
                                    v-on:input="getOfferedGrossPerYear"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    class="form-control"
                                    aria-describedby="reasonHelpInline"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Offered Gross per Month
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                {{employee.offered_gross_per_month}}
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Effective From
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-row>
                                    <b-col lg="6" sm="12" class="mb-1rem">
                                        <div class="form-group row">
                                            <label class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap">
                                                Month
                                                <span class="text-danger" style="font-weight:bold;">*</span>
                                            </label>
                                            <div class="col-lg-9 col-md-10 col-sm-12">
                                                <b-form-select
                                                    v-model="month"
                                                    :options="months"
                                                    disabled-field="notEnabled"
                                                ></b-form-select>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col lg="6" sm="12" class="mb-1rem">
                                        <div class="form-group row">
                                            <label class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap">
                                                Year
                                                <span class="text-danger" style="font-weight:bold;">*</span>
                                            </label>
                                            <div class="col-lg-9 col-md-10 col-sm-12">
                                                <b-form-select
                                                    v-model="year"
                                                    :options="years"
                                                    disabled-field="notEnabled"
                                                ></b-form-select>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-8">
                        <div class="form-group row" style="float:right !important;">
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    class="btn btn-primary text-right ma-10px"
                                    @click="createEmployee"
                                >Submit</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    @click="$router.go(-1)"
                                    class="btn btn-secondary ma-10px"
                                >Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
        </b-container>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import default_error from "../../../assets/js/global";
import Multiselect from 'vue-multiselect';
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        DatePick,
        Multiselect,
        EmployeeList
    },
    props: {
        characters: {
            type: String,
            default: 'a-z,A-Z,0-9,!@#'
        }
    },
    data() {
        return {
            selectable_years: {
                from : null,
                to: null
            },
            dob_selectable_years: {
                from : null,
                to: null
            },
            doj_selectable_years: {
                from : null,
                to: null
            },
            week_off:"",
            employee:{
                dob: "",
                id:"",
                full_name: "",
                doj:new Date().toLocaleString("sv").substring(0, 10),
                designation:"",
                employee_group_id: "",
                holiday_group_id: "",
                leave_group_id:"", 
                week_off:"",
                reporting_to:"",
                offered_gross_per_year:"",
                offered_gross_per_month:"",
                effective_from:"",  
                email:"",
                password: "",
                role: [],   
                esic:"",
                uan:"",
                leaves_initial_credit:"",
            },
            roles: [],
            type: "password",
            title: "Show",
            icon: "eye",
            salary_slabs: [],
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            employee_groups:[
                { item: '', name: "Select Employee Group" },
            ],
            holiday_groups:[
                { item: '', name: "Select Holiday Group" },
            ],
            leave_groups:[
                { item: '', name: "Select Leave Group" },
            ],
            week_offs: [
                {value: 'Monday', name: "Monday"},
                {value: 'Tuesday', name: "Tuesday"},
                {value: 'Wednesday', name: "Wednesday"},
                {value: 'Thursday', name: "Thursday"},
                {value: 'Friday', name: "Friday"},
                {value: 'Saturday', name: "Saturday"},
                {value: 'Sunday', name: "Sunday"}
            ],
            suggested_id:"",
            doj_previous_years_diff:1,
            doj_future_years_diff:1,
            future_years_diff:1,
            previous_years_diff:1,
            dob_previous_years_diff:60,
            page_loader: false,
            from_date: new Date().getFullYear() - 1,
            to_date: new Date().getFullYear() + 1, 
            month:String(new Date().getMonth()+1),
            year:'',
            years: [],
            generate_password_length:12,
            months: [
                { value: "1", text: "January" },
                { value: "2", text: "February" },
                { value: "3", text: "March" },
                { value: "4", text: "April" },
                { value: "5", text: "May" },
                { value: "6", text: "June" },
                { value: "7", text: "July" },
                { value: "8", text: "August" },
                { value: "9", text: "September" },
                { value: "10", text: "October" },
                { value: "11", text: "November" },
                { value: "12", text: "December" }
            ],
            date:'01',
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_CREATE_EMPLOYEE");
        let current_date = new Date;
        this.selectable_years.to = current_date.getFullYear() + this.future_years_diff;
        this.selectable_years.from =current_date.getFullYear() -this.previous_years_diff;
        this.doj_selectable_years.to = current_date.getFullYear() + this.doj_future_years_diff;
        this.doj_selectable_years.from =2010;
        this.getSuggestedId();
        this.getRoles();
        this.getEmployeeGroups();
        this.getHolidayGroups();
        this.getLeaveGroups();
        if (this.hasPermission('CAN_MANAGE_SALARY_SLABS')) {
            this.getEmployeesSalarySlabs();
        }
        this.getYears();
        
        this.week_off = [{value: 'Sunday', name: "Sunday"}];
    },
    methods: {
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },
        PasswordCharGenerate() {
            let dynCharArr = this.characters.split(',');  
            let LiveCharGenarte_nchar = '';
            let password = '';
            if( dynCharArr.indexOf('0-9') >= 0) {
                LiveCharGenarte_nchar += '0123456789!@#';
            }
            if( dynCharArr.indexOf('a-z') >= 0) {
                LiveCharGenarte_nchar += 'abcdefghijklmnopqrstuvwxyz!@#';
            }
            if( dynCharArr.indexOf('!@#') >= 0) {
                LiveCharGenarte_nchar += '!@#';
            }    
            if( dynCharArr.indexOf('A-Z') >= 0) {
                LiveCharGenarte_nchar += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#';
            }
            LiveCharGenarte_nchar += "1234567890!@#";
            for(let i=0; i < this.generate_password_length; i++) {
                password += LiveCharGenarte_nchar.charAt(Math.floor(Math.random() * LiveCharGenarte_nchar.length));
            }
            this.employee.password = password;
        },

        getRoles: function() {
            const roles = this.$store.getters.getAppSetting("hrms_user_interface", "create_employee_roles");
            roles.forEach(role => {
                this.roles.push({ text: role, value: role });
            });
            this.employee.role = ['EMPLOYEE'];
        },
        
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.employee[key] = this.strTitleCase(event.target.value);
        },

        getEmpId(id) {
            this.employee.reporting_to = id;
        },

        getYears() {
            let years = [];
            let year = (new Date().getFullYear()) + 1;
            for (var index = 2010; index <= year; index++) {
                years.push({
                    value: index, text:index
                })
            }
            this.years = years;
            this.year = new Date().getFullYear();
        },

        uppercase (event) {
            return this.employee.ifsc_code = event.target.value.toUpperCase();
        },

        getOfferedGrossPerYear(event) {
            if (event.target.value) {
                this.employee.offered_gross_per_month = event.target.value/12;
                this.employee.offered_gross_per_month = this.employee.offered_gross_per_month.toFixed(2);
            }
        },

        getSuggestedId() {
            this.page_loader= true;
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_suggested_id, query).then(response => {
                this.suggested_id = response.data.data;
                this.page_loader = false;
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                    this.page_loader = false;
                }
            });
        },

        getEmployeeGroups() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_employee_groups, query).then(response => {
                let result = response.data.data;
                result.forEach(employee_group => {
                    this.employee_groups.push({
                        item: employee_group.id,
                        name: employee_group.name
                    });
                });
                this.employee.employee_group_id = result[0].id
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        getHolidayGroups() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_holiday_group_list, query).then(response => {
                let result = response.data.data;
                result.forEach(holiday_group => {
                    this.holiday_groups.push({
                        item: holiday_group.id,
                        name: holiday_group.name
                    });
                });
                this.employee.holiday_group_id = result[0].id;
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        getLeaveGroups: function() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_leave_groups_list, query).then(response => {
                let result = response.data.data;
                result.forEach(leave_group => {
                    this.leave_groups.push({
                        item: leave_group.id,
                        name: leave_group.name
                    });
                });
                this.employee.leave_group_id = result[0].id;
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        getEmployeesSalarySlabs() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            this.axios.get(this.$api.get_salary_slabs, query)
                .then(response => {
                    response.data.data.forEach(employee_salary_slab => {
                        this.salary_slabs.push({
                            value:employee_salary_slab.id, text: employee_salary_slab.name
                        })
                    });
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        createEmployee: function() {
            this.startProgressBar();  
            this.employee.week_off = this.week_off;
            if(this.employee.week_off) {
                let weekoff = [];
                this.employee.week_off.forEach(week_offs => {
                    weekoff.push(week_offs.value);
                })
                this.employee['week_off'] = weekoff;  
            }
            if (this.month.toString().length<2) {
                let month = '0'+this.month;
                this.employee.effective_from = this.year+'-'+month+'-'+this.date;
            } else {
                this.employee.effective_from = this.year+'-'+this.month+'-'+this.date;
            }
            this.employee.id = this.suggested_id;
            const data = this.deleteEmptyKeys(this.employee);
            this.axios.post(this.$api.create_employee, data, this.getAuthHeaders()).then(() => {
                this.updateProgressBar(true);
                this.$router.go(-1);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.employee.week_off = "";
                this.updateProgressBar(false);
                this.showAlert();
                window.scrollTo(0, 0);
            });
        },
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}

.badge-red-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

</style>
